import { nanoid } from 'nanoid';
import resume from '../documents/Krzysztof_Jakubiak_CV.pdf';

// HEAD DATA
export const headData = {
  title: 'Front-end Developer',
  description: 'Welcome to my Portfolio Site!',
};

// HERO DATA
export const heroData = {
  title: `Hello, I'm `,
  name: 'Krzysztof Jakubiak',
  subtitle: `I'm front-end developer`,
  cta: 'View my work',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphTwo: `On a daily basis I am a sports instructor, I really like to jump on a trampoline, stand on my hands and do flips.`,
  resume,
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'chris-clothing.png',
    title: 'Chris Clothing',
    info: `It is an E - commerce platform that allows you to manage selected items in the cart.`,
    url: 'https://chris-clothing.krzysztof-jakubiak.pl/',
    repo: 'https://github.com/kj-ninja/chris-clothing',
    skills: [
      {technology: 'React', img: 'react.svg'},
      {technology: 'Redux', img: 'redux.svg'},
      {technology: 'Styled Components', img: 'styled.png'},
      {technology: 'Local Storage', img: 'local.png'},
      {technology: 'Firebase', img: 'firebase.svg'}
    ],
  },
  {
    id: nanoid(),
    img: 'meal-plan.jpg',
    title: 'Meal Plan',
    info: `Meal Plan is an application that allows users to save their favorite recipes and, above all, plan their meals.`,
    url: 'https://meal-plan.krzysztof-jakubiak.pl/',
    repo: 'https://github.com/kj-ninja/meal-plan',
    skills: [
      {technology: 'React', img: 'react.svg'},
      {technology: 'Redux', img: 'redux.svg'},
      {technology: 'Sass', img: 'sass.svg'},
      {technology: 'React Hook Form', img: 'rhf.png'},
      {technology: 'Firebase', img: 'firebase.svg'}
    ],
  },
  {
    id: nanoid(),
    img: 'strong-first.png',
    title: 'Strong First',
    info: `Strong First is an application, which motivates to practice sports by
    controlling strength and condition progress by the users.`,
    url: 'https://www.strong-first.com/',
    repo: 'https://github.com/kj-ninja/strong-first',
    skills: [
      {technology: 'React', img: 'react.svg'},
      {technology: 'Redux', img: 'redux.svg'},
      {technology: 'Sass', img: 'sass.svg'},
      {technology: 'Formik', img: 'formik.png'},
      {technology: 'Firebase', img: 'firebase.svg'}
    ],
  },
  {
    id: nanoid(),
    img: 'oddaj-rzeczy.jpg',
    title: 'Oddaj Rzeczy',
    info: `The goal of the project is to create place, in which everyone will be able to give unnecessary items to trusted institutions. 
    With "Oddam w dobre ręce" you can easily help other peoples by giving away, stuff you don't need it anymore.`,
    url: 'https://oddaj-rzeczy.krzysztof-jakubiak.pl/',
    repo: 'https://github.com/kj-ninja/oddaj-rzeczy',
    skills: [
      {technology: 'React', img: 'react.svg'},
      {technology: 'Sass', img: 'sass.svg'},
      {technology: 'Firebase', img: 'firebase.svg'},
      {technology: 'Formik', img: 'formik.png'},
      {technology: 'Bootstrap', img: 'bootstrap.svg'},
    ],
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'jakubiak.krzy@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/kj-ninja',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/krzysztof-jakubiak/',
    },
    {
      id: nanoid(),
      name: 'file-pdf-o',
      url: resume,
    },
  ],
};
